html,
body {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: var(--font-inter);
}

.smooth-scroll {
  scroll-behavior: smooth;
}

#__next {
  display: flex;
  flex: 1 1;
}

a {
  transition: color .2s ease;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--contrast) !important;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--contrast), 0 0 5px var(--contrast) !important;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: var(--contrast) !important;
  border-left-color: var(--contrast) !important;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.active {
  font-weight: 500;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.drawer-overlay {
  position: fixed !important;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  position: fixed !important;
  inset: 0;
  background: white;
  z-index: 1000;
  box-shadow: -3px 0 8px rgba(0, 0, 0, 0.2);
}

.drawer-content.from-left {
  left: 0;
}

.drawer-content.from-right {
  right: 0;
}

@media screen and (min-width: 768px) {
  .drawer-content {
    inset: unset;
    top: 0;
    bottom: 0;
    width: 500px;
  }
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: red;
  transform-origin: 0;
}

/* Used for editorial content (pages etc) */
.content {
  margin-bottom: 80px;
}

.content p {
  color: var(--color);
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
}

.content ul {
  margin: 10px 0;
  display: block;
}

.content li {
  display: list-item;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 15px;
}

.content a {
  text-decoration: underline;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.img-wrap:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
  border-radius: inherit;
  border: 1px solid #000000;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.landing-hero-image-wrapper {
  position: relative;
}
.landing-hero-image-wrapper::after {
  content: '';
  position: absolute;
  width: 70%;
  height: 50%;
  border-radius: 50%;
  bottom: 10%;
  left: 0;
  z-index: -1;
  background-color: #2d6936;
  filter: blur(90px);
  opacity: 0.9;
}

.landing-page-grid-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  grid-gap: 24px;
  gap: 24px;
}

.sale-page-grid-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  grid-gap: 40px;
  gap: 40px;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.header-wrapper {
  background-color: white;
}

.header-wrapper.sticky {
  background-color: rgba(255, 255, 255, 0.85);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
}

.popover-animation {
  animation: fadeIn .2s cubic-bezier(.455,.03,.515,.955) forwards;
}

.user-menu-animation {
  animation: fadeInFull .1s ease-in forwards;
}

.counter-input-animation {
  animation: fadeInFull .04s ease-in forwards;
}

.navigation-menu-animation {
  animation: navigation-menu_scaleIn .2s ease
}

@keyframes navigation-menu_scaleIn {
  0% {
      opacity: 0;
      transform: rotateX(-30deg) scale(.9)
  }

  to {
      opacity: 1;
      transform: rotateX(0deg) scale(1)
  }
}

@keyframes fadeIn {
  0% {
    opacity: .3
  }
  to {
    opacity: 1
  }
}

@keyframes fadeInFull {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@supports (((-webkit-backdrop-filter: saturate(180%) blur(20px)) or (backdrop-filter: saturate(180%) blur(20px)))) {
  .header::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    perspective: 1000;
    z-index: -1;
    top: -1px;
    transform: translateZ(0)
  }
}
/* mimic the native viewport full width/height + flex */
/* https://github.com/tamagui/tamagui/blob/master/packages/core/reset.css */
body, #root, #__next {
  display: unset;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  font-family: Inter;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

li, select, input, button, [role="slider"], [role="listbox"] {
  outline: none;
}

p,
input,
textarea,
button,
ul,
ol,
li,
pre,
dialog {
  all: unset;
  box-sizing: border-box;
}

.is_Anchor:has(.is_Button) {
  text-decoration: none;
}

input {
  outline: none !important;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.table-row-with-onclick:hover {
  cursor: pointer;
  background-color: var(--subtlePale);
}
@keyframes pulse {
	50% {
    opacity: .4;
  }
}

.skeleton-line {
  background-color: var(--border) !important;
  border-radius: 3px !important;
  color: var(--border) !important;
  transform: scaleY(0.7);
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  cursor: default !important;
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
  border-color: transparent !important;
}

.skeleton-box {
  background-color: var(--border) !important;
  color: var(--border) !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  cursor: default !important;
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
  border-color: transparent !important;
}

.skeleton-circle {
  background-color: var(--border) !important;
  border-radius: 1000px !important;
  color: var(--border) !important;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  cursor: default !important;
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
  border-color: transparent !important;
}

.skeleton-circle > *,
.skeleton-box > *,
.skeleton-line > * {
  opacity: 0 !important;
}


/* NOTE: For storybook snapshots */
.disable-animations .skeleton-circle,
.disable-animations .skeleton-line,
.disable-animations .skeleton-box {
  animation: unset !important;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ccafe3';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d1d9458b69004127-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ccafe3';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/b967158bc7d7a9fb-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ccafe3';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ae9ae6716d4f8bf8-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ccafe3';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c0f5ec5bbf5913b7-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ccafe3';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/b1db3e28af9ef94a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ccafe3';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/9c4f34569c9b36ca-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ccafe3';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/2aaf0723e720e8b9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_ccafe3';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_ccafe3 {font-family: '__Inter_ccafe3', '__Inter_Fallback_ccafe3';font-style: normal
}

